import { Injectable, ViewChild } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
// import { HeaderComponent } from '../user-master/header/header.component';
import { AuthenticationService } from '../_services';
import { HeaderModule } from '../user-master/header/header.module';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    @ViewChild(HeaderModule) _HeaderComponent;
    constructor(private authenticationService: AuthenticationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (err.status === 401) {
                // auto logout if 401 response returned from api
                this._HeaderComponent._logout();
                location.reload(true);
            }
            const error = err.error.message || err.statusText;
            return throwError(error);
        }));
    }
}
