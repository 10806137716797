// export const BASE_URL = 'http://148.72.207.113:3536/';  // FOR INTERNAL
// export const LAB_BASE_URL = 'http://148.72.207.113:3536';  // FOR INTERNAL

// export const BASE_URL = 'http://97.74.90.37:7925/';  // FOR INTERNAL
// export const LAB_BASE_URL = 'http://97.74.90.37:7925';  // FOR INTERNAL


//export const BASE_URL = 'http://mbplimsdemoapi.vvsindia.com/'; // FOR CLIENT
//export const LAB_BASE_URL = 'http://mbplimsdemoapitwo.vvsindia.com'; // FOR CLIENT


//export const BASE_URL = 'http://10.53.10.151/LimsAPI/';  // FOR PRODUCTION
//export const LAB_BASE_URL = 'http://10.53.10.151/LimsAPI';  // FOR PRODUCTION


//======= VVS Server build ===============
//ng build --prod
//========================================

//======= prod Server build ==============
//ng build --prod --base-href /lims/
//========================================

// export const BASE_URL = 'http://igicoalapi-stg.vvsindia.com/'; // FOR STAGING
// export const LAB_BASE_URL = 'http://igicoalapi-stg.vvsindia.com'; // FOR STAGING

//export const BASE_URL = 'https://bvlimsapi.vvsindia.com/'; // FOR LIVE
//export const LAB_BASE_URL = 'https://bvlimsapi.vvsindia.com'; // FOR LIVE


// export const BASE_URL = 'http://localhost:57649/'; // NEW BASE URL 08/07/2024
// export const LAB_BASE_URL = 'http://97.74.90.37:7925'; // NEW BASE URL 08/07/2024

// export const BASE_URL = 'http://97.74.90.37:7925/'; // NEW BASE URL 08/07/2024
// export const LAB_BASE_URL = 'http://97.74.90.37:7925'; // NEW BASE URL 08/07/2024


export const BASE_URL = 'https://igicoalapi.vvsindia.com/'; // FOR LIVE 2
export const LAB_BASE_URL = 'https://igicoalapi.vvsindia.com'; // FOR LIVE 2

export const FILE_BASE_URL = 'http://igicoalfiles-stg.vvsindia.com';
export const FILE_BASE_URL_2 = 'https://igicert.vvsindia.com/';

// MASTER / COMMON SERVICE PART //
export const API_LOGIN = `${BASE_URL}LoginAuth`;
export const API_LOGOUT = `${BASE_URL}api/LogOut/logout`;
export const API_LOGIN_AT_PLANT_CHANGE = `${BASE_URL}api/LoginAuthentication/GetLogIn`;
export const API_GET_UD = `${BASE_URL}api/Login/GetUserDtls`;
export const API_REG = `${BASE_URL}api/Login/PostRegistration`;
export const API_POST_CHANGE_PASSWORD = `${BASE_URL}api/LoginAuthentication/PostChangePassword`;
export const API_GET_PLANTS = `${BASE_URL}api/CommonData/GetAllPlant`;
// export const API_GET_USER_PLANTS = `${BASE_URL}api/CommonData/GetAllPlantByUser`;
export const API_GET_USER_PLANTS = `${BASE_URL}api/CommonData/GetAllProducerLoadingEnd`;
export const API_GET_USER_PLANTS_UNLOADING = `${BASE_URL}api/CommonData/GetAllProducerUnLoadingEnd`;
export const API_GET_COLLECTION_TYPES = `${BASE_URL}api/CommonData/GetAllCollectionType`;
export const API_GET_MINE_LIST = `${BASE_URL}api/CommonData/GetAllMine`;
export const API_GET_DO_LIST = `${BASE_URL}api/CommonData/GetAllDoByPlantMineCollType`;
export const API_GET_PLANT_MINE_LIST = `${BASE_URL}api/CommonData/GetAllPlantMine`;
export const API_GET_FEEDER_LIST = `${BASE_URL}api/CommonData/GetAllFeeder`;
export const API_GET_TABLES = `${BASE_URL}api/CommonData/GetTableName`;
export const API_GET_TABLE_SCHEMA = `${BASE_URL}api/CommonData/GetTableDtls`;
export const API_INSERT_UPDATE_MASTER_DATA = `${BASE_URL}api/MasterData/PostMasterDataInsertUpdate`;
export const API_GET_MASTER_DATA_LIST = `${BASE_URL}api/MasterData/GetMasterDataListing`;
export const API_GET_MASTER_DATA_DELETE = `${BASE_URL}api/MasterData/GetMasterDataDelete`;
export const API_GET_TRANSPORTER_LIST = `${BASE_URL}api/CommonData/GetAllTransporter`;
export const API_GET_DEPT_LIST = `${BASE_URL}api/CommonData/GetDepartment`;
export const API_GET_DESIGNATION_LIST = `${BASE_URL}api/CommonData/GetDesignation`;
export const GET_ALL_SAMPLE_PART_SIZE = `${BASE_URL}api/CommonData/GetAllSamplePartSize`;
export const GET_ALL_USER_CATEGORY = `${BASE_URL}api/CommonData/GetAllUserCategory`;
export const GET_ALL_USER_LIST = `${BASE_URL}api/CommonData/GetAllUserName`;
export const GET_ALL_MINE_BY_COLLECTION_TYPE = `${BASE_URL}api/CommonData/GetAllMineByCollType`;

// export const API_GET_USER_WISE_MINE_PLANT_MAP = `${BASE_URL}api/MasterData/GetUserWiseMinePlantMap`;
export const API_GET_USER_WISE_MINE_PLANT_MAP = `${BASE_URL}api/CommonData/GetAllPlantLoadingEnd`;
export const API_GET_USER_WISE_MINE_PLANT_MAP_UNLOADING = `${BASE_URL}api/CommonData/GetAllSubsidiaryUnLoadingEnd`;
export const API_GET_ALL_GRADE = `${BASE_URL}api/MasterData/GetAllGrade`;
export const API_GET_SIDING_SILO = `${BASE_URL}api/MasterData/GetSidingSilo`;
export const API_GET_SIDING_SOURCE = `${BASE_URL}api/MasterData/GetSidingSource`;
export const API_GET_USER_WISE_MINE_MAP = `${BASE_URL}api/MasterData/GetUserWiseMineMap`;
export const API_GET_USER_WISE_MINE_MAP_UNLOADING = `${BASE_URL}api/CommonData/GetAllPlantUnLoadingEnd`;
export const API_GET_CUSTOMERS = `${BASE_URL}api/CommonData/GetAllCustomer`;
// export const API_GET_RECEIVING_CUSTOMERS = `${BASE_URL}api/CommonData/GetAllReceivingCustomer`;
export const API_GET_RECEIVING_CUSTOMERS = `${BASE_URL}api/CommonData/GetAllConsumerLoadingEnd`;
export const API_GET_RECEIVING_CUSTOMERS_UNLOADING = `${BASE_URL}api/CommonData/GetAllConsumerUnLoadingEnd`;
export const API_GET_MGR_LOADING = `${BASE_URL}api/CommonData/GetMGRLoadingMode`;
export const API_GET_SAMPLE_MTD_YTD = `${BASE_URL}api/CommonData/GetSampleCollectedMTDYTD`;

export const API_GET_ALL_PLANT_MINE_LIST = `${BASE_URL}api/CommonData/GetAllMineMst`;

export const API_GET_ALL_SAMPLE_FOR_FEEDER = `${BASE_URL}api/CommonData/GetAllSamplePartSizeForFeeder`;
export const API_GET_ALL_SHIFT = `${BASE_URL}api/CommonData/GetAllShift`;

export const API_POST_TRANSPORTER_INSERT = `${BASE_URL}api/MasterData/PostTransMasterInsertUpdate`;
export const API_GET_TRANSPORTER_LISTING = `${BASE_URL}api/MasterData/GetTransMasterListing`;
export const API_GET_TRANSPORTER_DELETE = `${BASE_URL}api/MasterData/GetTransMasterDelete`;

export const API_GET_ALL_PLANT_MINES = `${BASE_URL}api/CommonData/GetAllPlantMine`;
export const API_GET_ALL_DO_LIST = `${BASE_URL}api/ManageDO/GetDONumberListing`;
export const API_GET_STATUS_CHANGE = `${BASE_URL}api/ManageDO/DOStatusUpdate`;
export const API_POST_DO_SAVE = `${BASE_URL}api/ManageDO/PostDo`;

export const API_GET_PLANT_MINE_DELETE = `${BASE_URL}api/MasterData/GetPlantMineMasterDelete`;

// USER MANAGEMENT //
export const GET_MENU_PANEL = `${BASE_URL}api/CommonData/GetMenuPanel`;
export const GET_USER_MASTER_INSERT_UPDATE = `${BASE_URL}api/UserManagement/GetUserMasterInsertUpdate`;
export const GET_USER_MASTER_LISTING = `${BASE_URL}api/UserManagement/GetUserMasterListing`;
export const GET_USER_STATUS_CHANGE = `${BASE_URL}api/UserManagement/GetUserStatusChange`;
export const GET_USER_RESET_PASSWORD = `${BASE_URL}api/UserManagement/GetUserResetPassword`;
export const GET_USER_ACL = `${BASE_URL}api/UserManagement/GetUserACL`;
export const USER_ACL_INSERT_UPDATE = `${BASE_URL}api/UserManagement/UserACLInsertUpdate`;
export const GET_MENU_TEMPLATE_LIST = `${BASE_URL}api/UserManagement/GetMenuTemplateDefaultList`;
export const GET_ACL_LISTING = `${BASE_URL}api/AccessControl/GetACLListing`;
export const GET_ACL_BY_MENU_TEMPID = `${BASE_URL}api/AccessControl/GetACLByMenuTempID`;
export const MENU_TEMPLATE_STATUS_CHANGE = `${BASE_URL}api/AccessControl/MenuTemplateStatusChange`;
export const GET_MENU_LISTING = `${BASE_URL}api/AccessControl/GetMenuListing`;
export const MENU_TEMPLATE_INSERT_UPDATE = `${BASE_URL}api/AccessControl/MenuTemplateInsertUpdate`;
export const TEMPLATE_LIST = `${BASE_URL}api/CommonData/GetMenuTemplate`;

// SAMPLE COLLECTION PART //
export const API_GET_BIN_BAG_NO = `${BASE_URL}api/SampleCollectionTruck/GetBinBagNumber`;
export const API_GET_AUGER_BY_PLANT = `${BASE_URL}api/CommonData/GetAllAuger`;
export const API_GET_BIN_BAG_MOVEMENT_LIST = `${BASE_URL}api/BinBagMovement/GetBinBagMovementListing`;
export const API_SENT_RECEIVED_BIN_BAG = `${BASE_URL}api/BinBagMovement/PostBinBagMovementAction`;
//export const API_GET_SAMPLE_COLLECTION_TRUCK_STATUS_INSERT = `${BASE_URL}api/SampleCollectionTruck/GetSampleCollectionTruckStatusInsert`;
export const API_GET_SAMPLE_COLLECTION_TRUCK_STATUS_INSERT = `${BASE_URL}api/SampleCollectionTruck/GetSampleCollectionTruckStatusInserted`;
export const API_GET_BIN_REASSING = `${BASE_URL}api/SampleCollectionTruck/GetBinReAssign`;
export const API_COLLECTION_RAIL_BPC_FEEDER = `${BASE_URL}api/SampleCollectionRailBPCFeeder/PostSampleCollectionFromRailBPCFeeder`;
export const GET_PREPARED_SAMPLE_LISTING = `${BASE_URL}api/SamplePreparation/GetPreparedSampleListing`;
export const GET_RAIL_BPC_FEEDER_LISTING = `${BASE_URL}api/SampleCollectionRailBPCFeeder/GetSampleFromRailBPCFeederListing`;
export const SET_BACK_OUT_OFF_SERVICE_BIN = `${BASE_URL}api/BinBagMovement/PostSetBackOutOffServiceBin`;
export const GET_OUT_ORDER_BINS_LISTING = `${BASE_URL}api/BinBagMovement/GetOutOffServiceBinList`;
export const API_BIN_SET_BACK = `${BASE_URL}api/BinBagMovement/GetSetBackOutOffServiceBin`;
export const API_GET_BIN_ASSIGN_SHORTAGE_ON_BIN_SEND = `${BASE_URL}api/BinBagMovement/GetBinAssignShortageOnBinSend`;

//For New Version //
export const API_SAMPLE_COLLECTION_TRUCK_SAVE = `${BASE_URL}api/SampleCollectionTruckNew/PostSampleCollectionTruckNew`;
export const API_SAMPLE_COLLECTION_TRUCK_LIST = `${BASE_URL}api/SampleCollectionTruckNew/GetSampleCollectionTruckNewListing`;
export const API_SAMPLE_COLLECTION_RAIL_RAKE_SAVE = `${BASE_URL}api/SampleCollectionRailNew/PostSampleCollectionRailNew`;
export const API_GET_FINYAER_CODE = `${BASE_URL}api/SampleCollectionRailNew/GetFinYearCode`;
export const API_SAMPLE_COLLECTION_RAIL_RAKE_LIST = `${BASE_URL}api/SampleCollectionRailNew/GetSampleCollectionRailNewListing`;
export const API_SAMPLE_COLLECTION_RAIL_RAKE_LIST_UNLOADING = `${BASE_URL}api/SampleCollectionRailNew/VTSRailNewUnloadingListing`;
export const API_SAMPLE_COLLECTION_RAIL_RAKE_DETAILS = `${BASE_URL}api/SampleCollectionRailNew/GetSampleCollectionDtlsRailNew`;
export const API_SAMPLE_COLLECTION_MGR_RAKE_DETAILS = `${BASE_URL}api/SampleCollectionMGRNew/GetSampleCollectionDtlsMGRNew`;

export const API_SAMPLE_COLLECTION_RAIL_RAKE_SUBLOTS = `${BASE_URL}api/SampleCollectionRailNew/GetSampleCollectionSubLotDtlsRailNew`;
export const API_SAMPLE_COLLECTION_MGR_RAKE_SUBLOTS = `${BASE_URL}api/SampleCollectionMGRNew/GetSampleCollectionSubLotDtlsMGRNew`;

//export const API_GET_BIN_BAG_NOS = `${BASE_URL}api/SampleCollectionTruck/GetBinBagNumbers`;
export const API_GET_BIN_BAG_NOS = `${BASE_URL}api/SampleSegregation/SegregateSample`;
export const API_RAIL_RAKE_SUBLOT_DETAILS = `${BASE_URL}api/SampleCollectionRailNew/GetRailRakeSubLotDtls`;
export const API_RAIL_RAKE_ADDTNL_INFO = `${BASE_URL}api/RakeAdditionalInfo/GetVTSRakeAdditionalInfoListing`;
export const API_TYPE = `${BASE_URL}api/RakeAdditionalInfo/GetAllType`;
export const API_LOCATION = `${BASE_URL}api/RakeAdditionalInfo/GetAllLocation`;
export const API_InsertVTSRakeAdditionalInfo = `${BASE_URL}api/RakeAdditionalInfo/InsertVTSRakeAdditionalInfo`;
export const API_InsertVTSRakeAdditionalInfoFile = `${BASE_URL}api/RakeAdditionalInfo/UploadVTSRakeAdditionalInfoFile`;

export const API_ALL_MINES_MASTER = `${BASE_URL}api/CommonData/GetAllMineMst`;
export const API_DO_COLLECTION_TYPES = `${BASE_URL}api/CommonData/GetAllCollectionTypeForDO`;
export const API_TRUCK_ADDTNL_INFO = `${BASE_URL}api/TruckAdditionalInfo/GetVTSTruckAdditionalInfoListing`;
export const API_TRUCK_TYPE = `${BASE_URL}api/TruckAdditionalInfo/GetAllType`;
export const API_TRUCK_LOCATION = `${BASE_URL}api/TruckAdditionalInfo/GetAllLocation`;
export const API_InsertVTSTruckAdditionalInfo = `${BASE_URL}api/TruckAdditionalInfo/InsertVTSTruckAdditionalInfo`;

export const API_SAMPLE_COLLECTION_MGR_RAKE_SAVE = `${BASE_URL}api/SampleCollectionMGRNew/PostSampleCollectionMGRNew`;
export const API_SAMPLE_COLLECTION_MGR_RAKE_LIST = `${BASE_URL}api/SampleCollectionMGRNew/GetSampleCollectionMGRNewListing`;
export const API_MGR_RAKE_SUBLOT_DETAILS = `${BASE_URL}api/SampleCollectionMGRNew/GetMGRRakeSubLotDtls`;
//For New Version //

// SAMPLE COLLECTION PART //


// SAMPLE PREPARATION PART //
//export const SAMPLE_PREPARATION_LIST_IN_MM = `${BASE_URL}api/SamplePreparation/GetCollectionReceiveForSamplePreparationListing`;
export const SAMPLE_PREPARATION_LIST_IN_MM = `${BASE_URL}api/SamplePreparation/GetSegregateSampleForPreparation`;
//export const RECEIVE_AND_PREPARATION_IN_MM = `${BASE_URL}api/SamplePreparation/GetCollectionReceiveForSamplePreparationAction`;
export const RECEIVE_AND_PREPARATION_IN_MM = `${BASE_URL}api/SamplePreparation/SamplePreparationAction`;
export const API_GET_FINAL_PREPARATION_LIST = `${BASE_URL}api/SamplePreparation/GetSampleReceiveForFinalPreparationListing`;
export const API_GET_FINAL_PREPARATION_ACTION = `${BASE_URL}api/SamplePreparation/GetSampleReceiveForFinalPreparationAction`;

//For Resampling Part //
export const SAMPLE_DETAILS = `${BASE_URL}api/ReSampling/GetSampleDtlsForResampling`;
export const RESAMPLE_ENTRY = `${BASE_URL}api/ReSampling/ReSamplingEntry`;
export const RESAMPLING_LIST = `${BASE_URL}api/ReSampling/GetSampleDtlsForResamplingListing`;
export const RESAMPLING_REPRINT_LIST = `${BASE_URL}api/SamplePreparation/GetPreparedSampleListingForReprint`;
//For Resampling Part //


//typemaster//
export const GET_TYPE_LIST = `${BASE_URL}api/TypeMaster/GetTypeList`;
export const INSERT_TYPE_MASTER = `${BASE_URL}api/TypeMaster/InsertUpdateTypeMaster`;
// SAMPLE PREPARATION PART //
//export const GET_VIEW_SAMPLE_SOURCE = `${BASE_URL}api/SourceInfo/GetSourceInfoBySampleRefNo`;
export const GET_VIEW_SAMPLE_SOURCE = `${BASE_URL}api/IGIResultManager/GetQuickSearchSampleNoWise`;
export const GET_SAMPLE_SIZE = `${BASE_URL}api/CommonData/GetAllSamplePartSizeSourceInfo`;
export const GET_COLLECTION_TYPE = `${BASE_URL}api/CommonData/GetAllCollectionType`;
export const GET_SEARCH_DETAILS = `${BASE_URL}api/SourceInfo/GetSourceInfoBySampleDateOrCollectionDate`
export const GET_EXPORT_TO_EXCEL = `${BASE_URL}api/SourceInfo/GetSourceInfoBySampleDateOrCollectionDateExportToExcel`

// LAB PART //
export const GET_FRONT_OFC_REG_URL = LAB_BASE_URL + '/api/FrontOffice/GetFrontOfficeRegister';
export const GET_LAB_CODE_URL = LAB_BASE_URL + '/api/FrontOffice/GetLabCode';
export const INSERT_REGISTER_DATA_URL = LAB_BASE_URL + '/api/FrontOffice/InsertFrontOfficeRegister';
export const GET_SAMPLE_REGISTER_URL = LAB_BASE_URL + '/api/LabSampleRegister/GetSampleRegister';
export const GET_PENDING_SAMPLE_URL = LAB_BASE_URL + '/api/LabTestInitiation/GetPendingSample';
export const GET_ANALYSIS_INITIATED_URL = LAB_BASE_URL + '/api/LabTestInitiation/GetAnalysisInitiated';
export const GET_PENDING_JOB_ORDER_LIST_URL = LAB_BASE_URL + '/api/LabJobOrderlist/GetPendingJobOrderlist';
export const GET_PENDING_JOB_ORDER_DTLS_URL = LAB_BASE_URL + '/api/LabJobOrderlist/GetPendingJobOrderDtls';
export const CHANGE_INITIATION_STATUS_URL = LAB_BASE_URL + '/api/LabTestInitiation/ChangeInitiationStatus';
export const CREATE_JOB_ORDER_URL = LAB_BASE_URL + '/api/LabTestInitiation/CreateJobOrder';
export const CHANGE_INITIATION_BY_BARCODE_URL = LAB_BASE_URL + '/api/LabTestInitiation/ChangeInitiationByBarcode';
export const GET_RESULT_FOR_TM_URL = LAB_BASE_URL + '/api/LabTestResultEntry/GetResultForTM';
export const SAVE_TM_RESULT_URL = LAB_BASE_URL + '/api/LabTestResultEntry/InsertTMResult';
export const GET_RESULT_FOR_QUALITY_URL = LAB_BASE_URL + '/api/LabTestResultEntry/GetResultForQuality';
export const SAVE_GCV_RESULT_URL = LAB_BASE_URL + '/api/LabTestResultEntry/InsertGCVResult';
export const SAVE_IMVM_RESULT_URL = LAB_BASE_URL + '/api/LabTestResultEntry/InsertIMVMResult';
export const SAVE_EM_RESULT_URL = LAB_BASE_URL + '/api/LabTestResultEntry/InsertEMResult';
export const GET_APPRVL_JO_LST_URL = LAB_BASE_URL + '/api/LabResultApproval/GetResultApprovalJOlist';
export const GET_RES_TM_APPRVL_URL = LAB_BASE_URL + '/api/LabResultApproval/GetResultForTMApproval';
export const APPROVE_TM_RES_URL = LAB_BASE_URL + '/api/LabResultApproval/ApproveTMResult';
export const GET_RES_QUALITY_APPRVL_URL = LAB_BASE_URL + '/api/LabResultApproval/GetResultForQualityApproval';
export const APPROVE_GCV_RES_URL = LAB_BASE_URL + '/api/LabResultApproval/ApproveGCVResult';
export const APPROVE_EM_RES_URL = LAB_BASE_URL + '/api/LabResultApproval/ApproveEMResult';
export const APPROVE_IMVM_RES_URL = LAB_BASE_URL + '/api/LabResultApproval/ApproveIMVMResult';
export const GET_PLANTS_BY_USER_URL = BASE_URL + 'api/CommonData/GetAllPlantByUser';
export const GET_MINES_URL = BASE_URL + 'api/CommonData/GetAllMine';
export const GET_FEEDERS_URL = BASE_URL + 'api/CommonData/GetAllFeeder';
export const GET_BPC_REPORT_DTL_URL = LAB_BASE_URL + '/api/LabTestReport/GetBPCReportDtls';
export const GET_FEEDER_REPORT_DTL_URL = LAB_BASE_URL + '/api/LabTestReport/GetFeederReportDtls';
export const GET_RAKE_REPORT_DTL_URL = LAB_BASE_URL + '/api/LabTestReport/GetRakeReportDtls';
export const GET_TRUCK_REPORT_DTL_URL = LAB_BASE_URL + '/api/LabTestReport/GetTruckReportDtls';
export const GET_FEEDER_REPORT_DTL_BEFORE_APPROVE_URL = LAB_BASE_URL + '/api/LabTestReport/GetFeederReportDtlsBeforeApproval';
export const GET_RAKE_REPORT_DTL_BEFORE_APPROVE_URL = LAB_BASE_URL + '/api/LabTestReport/GetRakeReportDtlsBeforeApproval';
export const GET_TRUCK_REPORT_DTL_BEFORE_APPROVE_URL = LAB_BASE_URL + '/api/LabTestReport/GetTruckReportDtlsBeforeApproval'

export const GET_SAMPLE_DTLS_FOR_CORRECTION = LAB_BASE_URL + '/api/LabTestResultEntryCorrection/GetSampleDtlsForCorrection';
export const GET_RESULT_FOR_TM_CORRECTION = LAB_BASE_URL + '/api/LabTestResultEntryCorrection/GetResultForTMCorrection';
export const GET_RESULT_FOR_QUALITY_CORRECTION = LAB_BASE_URL + '/api/LabTestResultEntryCorrection/GetResultForQualityCorrection';
// PAGE_CONFIG //
export const PAGE_CONFIG = {
    itemsPerPage: 20,
    currentPage: 1,
    totalItems: 0
};
export const TOASTER_TIMEOUT = {
    SUCCESS: { timeOut: 7000 },
    ERROR: { timeOut: 7000 }
};
export const NOT_FOUND = "No Records Found...";
export const DAY_DIFF: number = 92;



// LOCATION PART //
export const API_GET_LOCATION_LIST = BASE_URL + 'api/MasterData/GetLocationMasterDataListing';
export const API_INSERT_UPDATE_LOCATION_DATA = BASE_URL + 'api/MasterData/LocationMasterDataInsertUpdate';
//RFID MASTER//
export const API_GET_RFID_MASTER_LIST = `${BASE_URL}api/MasterData/GetRFIDMasterList`;
//RFID MASTER//
export const API_RFID_MASTER_INSERT_UPDATE = `${BASE_URL}api/RFIDNo/RFIDMasterInsertUpdate`;
export const API_GET_ALL_SAMPLING_POINT = `${BASE_URL}api/CommonData/GetAllSamplingPoint`;

//Ayan
export const GET_SIDING_SILO = `${BASE_URL}api/MasterData/GetSidingSilo`
export const GET_ALL_GRADE = `${BASE_URL}api/MasterData/GetAllGrade`
export const GET_USER_WISE_MINE_MAP = `${BASE_URL}api/MasterData/GetUserWiseMineMap`
export const GET_USER_WISE_MINE_MAP_RESULT_SUBMIT = `${BASE_URL}api/LabTestResultSubmit/GetSubsidiarylist`
export const API_SAMPLE_COLLECTION_cONVEYOR_BELT = `${BASE_URL}api/SampleCollectionConveyorBelt/PostSampleCollectionConveyorBelt`;
export const API_SAMPLE_COLLECTION_cONVEYOR_LIST = `${BASE_URL}api/SampleCollectionConveyorBelt/GetSampleCollectionConveyorBeltListing`;
export const API_SAMPLE_COLLECTION_cONVEYOR_LIST_UNLOADING = `${BASE_URL}api/SampleCollectionConveyorBelt/VTSConveyorBeltUnloadingListing`;
export const API_SAMPLE_COLLECTION_TRUCK_RCS_SAVE = `${BASE_URL}api/SampleCollectionRCR/PostSampleCollectionRCR`;
export const API_SAMPLE_COLLECTION_TRUCK_LIST_RCS = `${BASE_URL}api/SampleCollectionRCR/GetSampleCollectionRCRListing`;


//Result Submit
export const API_GET_TEST_RESULT_FOR_SUBMIT = `${BASE_URL}api/LabTestResultSubmit/GetTestResultForSubmit`;
export const API_UPDATE_LAB_TEST_RESULT = `${BASE_URL}api/LabTestResultSubmit/UpdateTestResult`;
export const API_SUBMIT_LAB_TEST_RESULT = `${BASE_URL}api/LabTestResultSubmit/SubmitTestResult`;
export const API_GET_REFFEREE_LISTING = `${BASE_URL}api/IGIResultManager/GetAllIGIReportChallengeInProgress`;
export const API_UPDATE_PAYMENT_DETAILS = `${BASE_URL}api/IGIResultManager/UpdatePaymentDetailAction`;
export const API_GET_COMPLETED_TEST_RESULT = `${BASE_URL}api/IGIResultManager/GetAllIGIReportCompleted`;
export const API_GET_REFEREE_RESULT = `${BASE_URL}api/IGIResultManager/GetCustomerAndRefreeDataByTestResultId`;
export const API_DOWNLOAD_REFEREE_DOC = `${FILE_BASE_URL}/api/IGIResultManager/DownloadDocument`;
export const API_GENERATE_SAMPLE_REPORT = `${BASE_URL}api/Report/GenerateSampleReport`;

//Third Party Lab
export const API_GET_THIRD_PARTY_LAB_LISTING = `${BASE_URL}api/IGIResultManager/GetAllTestReportSendForLab`
export const API_GET_ALL_THIRD_PARTY_LAB = `${BASE_URL}api/CommonData/GetAllThirdPartyLab`
export const API_SEND_TO_THIRD_PARTY_LAB = `${BASE_URL}api/IGIResultManager/SendToThirdPartyLab`

//Referee Result Uplooad
export const API_GET_REFREE_UPLOAD_LISTING = `${BASE_URL}api/IGIResultManager/GetAllRefResultUpdateList`
export const API_UPDATE_REFEREE_RESULT = `${BASE_URL}api/IGIResultManager/UpdateRefereeResult`;
export const API_USER_MANUAL = `${FILE_BASE_URL}/UserManual/BVPortal.pdf`;

//File Download
export const API_DOWNLOAD_TEST_REPORT = `${FILE_BASE_URL_2}public/certuplike/`
export const API_DOWNLOAD_GCV_REPORT = `${FILE_BASE_URL_2}gcvcertdown`

//Master
export const API_GET_AREA_LIST = `${BASE_URL}api/AreaSubsidiaryMaster/GetSubsidiary`
export const API_GET_AREA_TABLE_LISTING = `${BASE_URL}api/AreaSubsidiaryMaster/GetAreaSubsidiary`
export const API_GET_ADD_AREA_LIST = `${BASE_URL}api/AreaSubsidiaryMaster/AddAreaSubsidiary`
export const API_GET_SIDING_TABLE_LIST = `${BASE_URL}api/SidingMaster/GetAllSidingMasterList`
export const API_GET_SIDING_TREE = `${BASE_URL}api/SidingMaster/GetSidingTreeNode`
export const API_GET_SIDING_FOR_UPDATE = `${BASE_URL}api/SidingMaster/GetAllSidingMasterListEditById`
export const API_GET_ADD_SIDING = `${BASE_URL}api/SidingMaster/InsertUpdateSidingMaster`
export const API_GET_CONSUMER_LIST = `${BASE_URL}api/ConsumerMaster/GetAllConsumerList`
export const API_GET_CONSUMER_TREE = `${BASE_URL}api/ConsumerMaster/GetAllSubsidaryAreaTreeNode`
export const API_GET_AREA = `${BASE_URL}api/AreaSubsidiaryMaster/GetArea`
export const API_GET_ALL_SIDING_MASTER = `${BASE_URL}api/SidingMaster/GetAllSidingMaster`
export const API_GET_CONSUMER_TREE_NODE = `${BASE_URL}api/UserManagement/GetConsumerTreeNode`
export const API_GET_ALL_CATEGORY_MASTER = `${BASE_URL}api/UserManagement/GetAllCategoryMaster`

export const API_INSERT_UPDATE_CONSUMER_SUBSIDARY = `${BASE_URL}api/ConsumerMaster/InsertUpdateConsumerSubsidary`
export const API_GET_CUSTOMER_DETAILS_BYID = `${BASE_URL}api/ConsumerMaster/GetAllCustomerMasterListEditById`
export const API_GET_ALL_CUSTOMER_NAME_LIST = `${BASE_URL}api/ConsumerMaster/GetAllCustomerNameList`

export const API_INSERT_UPDATE_USER_MASTER = `${BASE_URL}api/UserManagement/InsertUpdateUserMaster`
export const API_GET_ALL_USER_LIST = `${BASE_URL}api/UserManagement/GetAllUserMasterDataList`
export const API_GET_USER_FOR_EDIT = `${BASE_URL}api/UserManagement/GetAllUserMasterDataListById`

export const API_GET_SUBSIDIARY_PRODUCER = `${BASE_URL}api/AreaSubsidiaryMaster/GetProducer`
export const API_GET_SUBSIDIARY_LIST = `${BASE_URL}api/AreaSubsidiaryMaster/GetAllSubsidiary`
export const API_ADD_SUBSIDIARY = `${BASE_URL}api/AreaSubsidiaryMaster/AddSubsidiary`

export const API_GET_PRODUCER_LIST = `${BASE_URL}api/AreaSubsidiaryMaster/GetProducerAllList`
export const API_ADD_PRODUCER = `${BASE_URL}api/AreaSubsidiaryMaster/AddProducer`

export const API_GET_SOURCE_TREENODE = `${BASE_URL}api/AreaSubsidiaryMaster/GetSourceTreeNode`
export const API_GET_SOURCE = `${BASE_URL}api/AreaSubsidiaryMaster/GetAllSource`
export const API_INSER_UPDATE_SOURCE_MASTER = `${BASE_URL}api/AreaSubsidiaryMaster/InsertUpdateSourceMaster`
export const API_GET_ALL_SOURCE_MASTER_LIST_EDIT_BYID = `${BASE_URL}api/AreaSubsidiaryMaster/GetAllSourceMasterListEditById`
export const API_UPDATE_OTHER_INFO = `${BASE_URL}api/LabTestResultSubmit/InsertUpdateTestResultOtherInfo`
export const API_BULK_UPLOAD_RESULT_DATA = `${BASE_URL}api/IGIResultManager/BulkUploadResultData`
export const API_GET_ALL_NOTIFICATION_DETAILS = `${BASE_URL}api/Notification/GetAllNotificationDetails`
export const API_GET_ALL_SUBSIDIARY = `${BASE_URL}api/CommonData/GetAllSubsidiary`

export const API_UPDATE_TEST_RESULT = `${BASE_URL}api/ChallengedDataInfo/UpdateTestResult`
export const API_GET_ALL_IGI_REPORT_FOR_REJECTION_CHALLENGE = `${BASE_URL}api/IGIResultManager/GetAllIGIReportForRejectionChallenge`

export const API_CONVEYOR_BELT_ADDITIONAL_INFO_LISTING = `${BASE_URL}api/ConveyorBeltAddlInfo/GetVTSConveyorBeltAdditionalInfoListing`;
export const API_INSERT_VTS_CONVEYOR_BELT_ADDITIONAL_INFO = `${BASE_URL}api/ConveyorBeltAddlInfo/InsertVTSConveyorBeltAdditionalInfo`;
export const API_GET_TEST_REPORT_NUMBER = `${BASE_URL}api/IGIResultManager/GetTestReportNumber`
export const API_TEST_AND_GCV_REPORT_UPLOAD = `${BASE_URL}api/IGIResultManager/TestAndGcvReportUpload`

export const API_GET_VTS_RCR_ADDITIONAL_INFO_LISTING = `${BASE_URL}api/RCRAdditionalInfo/GetVTSRCRAdditionalInfoListing`
export const API_INSERT_VTS_RCR_ADDITIONAL_INFO = `${BASE_URL}api/RCRAdditionalInfo/InsertVTSRCRAdditionalInfo`

export const API_INSERT_SAMPLE_COLLECTION_ROPEWAY = `${BASE_URL}api/SampleCollectionRopeWay/PostSampleCollectionRopeWay`
export const API_ROPEWAY_LOADING_LISTING = `${BASE_URL}api/SampleCollectionRopeWay/VTSRopeWayLoadingListing`
export const API_ROPEWAY_ADDITIONAL_INFO_LISTING = `${BASE_URL}api/RopeWayAddlInfo/GetVTSRopewayAdditionalInfoListing`
export const API_INSERT_VTS_ROPEWAY_BELT_ADDITIONAL_INFO = `${BASE_URL}api/RopeWayAddlInfo/InsertVTSRopeWayAdditionalInfo`










