import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {
  SAMPLE_PREPARATION_LIST_IN_MM,
  API_GET_FINAL_PREPARATION_LIST,
  API_GET_FINAL_PREPARATION_ACTION,
  RECEIVE_AND_PREPARATION_IN_MM,
  GET_PREPARED_SAMPLE_LISTING,
  SAMPLE_DETAILS,
  RESAMPLE_ENTRY,
  RESAMPLING_LIST,
  RESAMPLING_REPRINT_LIST,
  API_RFID_MASTER_INSERT_UPDATE
} from '../../_common/constant';
export interface SamplePreparationListInMM {
  SlNo: number;
  PlantID: number;
  PlantCode: string;
  Temporary: string;
  ReferenceNo: string;
  PartSizeID: number;
  PartSize: string;
  UOM: string;
  PartSizeUOM: string;
  Status: string;
  IsVisibleReceiveBtn: boolean;
  IsVisible125MMBtn: boolean;
  IsVisible35MMBtn: boolean;
}
export interface ReceivedAndPreparationInMM {
  StatusCode: string;
  SampleRefNo: string;
  NoOfBarcodePrint: string;
  ErrorMessage: string;
}

@Injectable({
  providedIn: 'root'
})
export class SamplePreparationService {

  constructor(private httpClient: HttpClient) { }

  // SAMPLE PREPARATION : 12.5 & 3.5 MM LIST //
  samplePreparationListInMM(referenceNo: string) {
    return this.httpClient.post<SamplePreparationListInMM>(`${SAMPLE_PREPARATION_LIST_IN_MM}`, { 'ReferenceNo': referenceNo }).pipe(catchError(() => throwError('Error occurs')));
  }

  // GET ALL FINAL PREPARATION LIST //
  _finalPreparationList(queryParam: string) {
    return this.httpClient.get(API_GET_FINAL_PREPARATION_LIST + queryParam);
  }

  // FINAL PREPARATION ACTION //
  _finalPreparationAction(queryParam: string) {
    return this.httpClient.get(API_GET_FINAL_PREPARATION_ACTION + queryParam);
  }

  // COLLECTION RECEIVED & PREPARATION FOR 12.50MM & 3.50MM ACTION //
  receivedAndPrepration(json: any) {
    return this.httpClient.post<ReceivedAndPreparationInMM>(`${RECEIVE_AND_PREPARATION_IN_MM}`, json)
      .pipe(catchError(() => throwError('Error occurs')));
  }

  // PREPARED SAMPLE LISTING//
  preparedSampleListing(
    plantID: number,
    sampleRefNo: string,
    partSizeID: number,
    fromDate: string,
    toDate: string,
    currentPage: number,
    pageSize: number) {
    let QRY_PARAMS = `?PlantID=${plantID}&SampleRefNo=${sampleRefNo}&PartSizeID=${partSizeID}&FromDate=${fromDate}&ToDate=${toDate}&CurrentPage=${currentPage}&PageSize=${pageSize}`
    return this.httpClient.get(`${GET_PREPARED_SAMPLE_LISTING}${QRY_PARAMS}`)
      .pipe(catchError(() => throwError('Error occurs')));
  }

  _postResamplingEntry(frmData: any) {
    return this.httpClient.post(RESAMPLE_ENTRY, frmData);
  }
  _getSampleDetails(queryParam: string) {
    return this.httpClient.get(SAMPLE_DETAILS + queryParam);
  }
  _getResamplingList(queryParam: string) {
    return this.httpClient.get(RESAMPLING_LIST + queryParam);
  }

  preparedSampleReprintListing(queryParam: string) {
    return this.httpClient.get(`${RESAMPLING_REPRINT_LIST}${queryParam}`)
      .pipe(catchError(() => throwError('Error occurs')));
  }

  _postRFIDMasterInsertUpdate(frmData: any) {
    return this.httpClient.post(API_RFID_MASTER_INSERT_UPDATE, frmData);
  }
}
