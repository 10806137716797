import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  API_COLLECTION_RAIL_BPC_FEEDER,
  GET_RAIL_BPC_FEEDER_LISTING,
  API_GET_BIN_BAG_NO,
  API_GET_AUGER_BY_PLANT, API_GET_BIN_BAG_MOVEMENT_LIST,
  API_SENT_RECEIVED_BIN_BAG,
  API_GET_SAMPLE_COLLECTION_TRUCK_STATUS_INSERT,
  SET_BACK_OUT_OFF_SERVICE_BIN,
  API_GET_BIN_REASSING,
  GET_OUT_ORDER_BINS_LISTING,
  API_BIN_SET_BACK,
  API_SAMPLE_COLLECTION_TRUCK_LIST,
  API_SAMPLE_COLLECTION_TRUCK_SAVE,
  API_SAMPLE_COLLECTION_RAIL_RAKE_SAVE,
  API_SAMPLE_COLLECTION_RAIL_RAKE_LIST,
  API_SAMPLE_COLLECTION_RAIL_RAKE_DETAILS,
  API_SAMPLE_COLLECTION_RAIL_RAKE_SUBLOTS,
  API_GET_BIN_BAG_NOS,
  API_RAIL_RAKE_SUBLOT_DETAILS,
  API_GET_ALL_SAMPLE_FOR_FEEDER,
  API_GET_ALL_SHIFT,
  API_RAIL_RAKE_ADDTNL_INFO,
  API_TYPE,
  API_LOCATION,
  API_InsertVTSRakeAdditionalInfo,
  API_TRUCK_ADDTNL_INFO,
  API_TRUCK_TYPE,
  API_TRUCK_LOCATION,
  API_InsertVTSTruckAdditionalInfo,
  API_GET_FINYAER_CODE,
  API_InsertVTSRakeAdditionalInfoFile,
  API_GET_BIN_ASSIGN_SHORTAGE_ON_BIN_SEND,
  API_SAMPLE_COLLECTION_MGR_RAKE_SAVE,
  API_SAMPLE_COLLECTION_MGR_RAKE_LIST,
  API_MGR_RAKE_SUBLOT_DETAILS,
  API_SAMPLE_COLLECTION_MGR_RAKE_SUBLOTS,
  API_SAMPLE_COLLECTION_MGR_RAKE_DETAILS,
  API_SAMPLE_COLLECTION_cONVEYOR_LIST,
  API_SAMPLE_COLLECTION_cONVEYOR_BELT,
  API_SAMPLE_COLLECTION_TRUCK_RCS_SAVE,
  API_SAMPLE_COLLECTION_TRUCK_LIST_RCS,
  API_SAMPLE_COLLECTION_RAIL_RAKE_LIST_UNLOADING,
  API_SAMPLE_COLLECTION_cONVEYOR_LIST_UNLOADING,
  API_CONVEYOR_BELT_ADDITIONAL_INFO_LISTING,
  API_INSERT_VTS_CONVEYOR_BELT_ADDITIONAL_INFO,
  API_GET_VTS_RCR_ADDITIONAL_INFO_LISTING,
  API_INSERT_VTS_RCR_ADDITIONAL_INFO,
  API_INSERT_SAMPLE_COLLECTION_ROPEWAY,
  API_ROPEWAY_LOADING_LISTING,
  API_ROPEWAY_ADDITIONAL_INFO_LISTING,
  API_INSERT_VTS_ROPEWAY_BELT_ADDITIONAL_INFO

} from '../../_common/constant';
import { of, throwError } from 'rxjs';
import { catchError, expand, map, switchMap, takeWhile, toArray } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})

export class SampleCollectionService {

  constructor(private http: HttpClient) { }

  // GET AUGER LIST //
  _augerList(queryParam: string) {
    return this.http.get(API_GET_AUGER_BY_PLANT + queryParam);
  }

  // GET BIN OR BAG NUMBER //
  _getBinBagNo(queryParam: string) {
    return this.http.get(API_GET_BIN_BAG_NO + queryParam);
  }

  // GET BIN OR BAG NUMBER //
  /*_getBinBagNumber(vehicleRegNo: string) {
    return this.http.get(`${API_GET_BIN_BAG_NO}?AugerID=${augerID}&AugerCode=${augerCode}&VehicleRegNo=${vehicleRegNo}`).pipe(
      catchError(() => throwError('Error Occurs'))
    );
  }*/

  _getBinBagNumber(vehicleRegNo: string) {
    return this.http.post(`${API_GET_BIN_BAG_NOS}`, { 'CSCTSVTSRefNo': vehicleRegNo }).pipe(
      catchError(() => throwError('Error Occurs'))
    );
  }

  // COLLECT SAMPLE OR SKIP //
  _getSampleCollectionInsert(queryParam: any) {
    return this.http.post(API_GET_SAMPLE_COLLECTION_TRUCK_STATUS_INSERT, queryParam);
  }

  // BIN RE-ASSING WITH NEW ID //
  _getbinReAssign(queryParam: string) {
    return this.http.get(API_GET_BIN_REASSING + queryParam);
  }

  // ALL BIN/BAG MOVEMENT LIST //
  _getBinBagMovementList(queryParam: string) {
    return this.http.get(API_GET_BIN_BAG_MOVEMENT_LIST + queryParam);
  }

  // THIS FUNCTION IS USED TO SENT AND RECEIVED BIN/BAG DATA //
  _postBinBagSentRecieved(binbagData: any) {
    return this.http.post(API_SENT_RECEIVED_BIN_BAG, binbagData);
  }

  // RAIL RAKE, BPC, FEEDER & ROAD COLLECTION //
  _collectionRailBpcFeeder(rrbfData: any) {
    return this.http.post(API_COLLECTION_RAIL_BPC_FEEDER, rrbfData);
  }

  // GET RAIL BPC FEEDER LISTING //
  railBpcFeederRoadListing(
    plantID: number,
    colTypeID: number,
    mineFeeder: number,
    mineFeederID: number,
    railRakeNo: string,
    transID: number,
    fromDate: string,
    toDate: string,
    currentPage: number,
    pageSize: number) {
    const QRY_PARAMS = `?PlantID=${plantID}&CollectionTypeID=${colTypeID}&MineFeeder=${mineFeeder}&MineFeederID=${mineFeederID}&RailRakeNo=${railRakeNo}&TransID=${transID}&FromDate=${fromDate}&ToDate=${toDate}&CurrentPage=${currentPage}&PageSize=${pageSize}`
    return this.http.get(`${GET_RAIL_BPC_FEEDER_LISTING}${QRY_PARAMS}`).pipe(
      catchError(() => throwError('Error occurs'))
    );
  }

  // SET BACK OUT OFF SERVICE BIN //
  postSetBackOutOffServiceBin(oops: any) {
    return this.http.post(SET_BACK_OUT_OFF_SERVICE_BIN, oops).pipe(catchError(() => throwError('Error Occurs')));
  }

  // ALL BIN OUT OF ORDER LIST //
  _getBinOutofOrderList(queryParam: string) {
    return this.http.get(GET_OUT_ORDER_BINS_LISTING + queryParam);
  }

  // BIN SET BACK //
  _getBinSetBack(queryParam: string) {
    return this.http.get(API_BIN_SET_BACK + queryParam);
  }

  //For New Version //
  _getSampleCollectionTruckList(queryParam: string) {
    return this.http.get(API_SAMPLE_COLLECTION_TRUCK_LIST + queryParam);
  }
  _postSampleCollectionTruckSave(rrbfData: any) {
    let retryCount = 0;
    const maxRetries = 2;
    return this.http.post(API_SAMPLE_COLLECTION_TRUCK_SAVE, rrbfData).pipe(
      expand(response => {
        if (response['StatusCode'] === '730' && retryCount < maxRetries) {
          retryCount++;
          return this.http.post(API_SAMPLE_COLLECTION_TRUCK_SAVE, rrbfData);
        } else {
          return of(response);
        }
      }),
      takeWhile((response, index) => response['StatusCode'] === '730' && index <= maxRetries, true),
      toArray(),
      map(responses => responses[responses.length - 1]),
      catchError(error => {
        console.error('Error:', error);
        return of(null);
      })
    );
  }
  _postSampleCollectionRailRakeSave(rrbfData: any) {
    let retryCount = 0;
    const maxRetries = 2;
    return this.http.post(API_SAMPLE_COLLECTION_RAIL_RAKE_SAVE, rrbfData).pipe(
      expand(response => {
        if (response['StatusCode'] === '730' && retryCount < maxRetries) {
          retryCount++;
          return this.http.post(API_SAMPLE_COLLECTION_RAIL_RAKE_SAVE, rrbfData);
        } else {
          return of(response);
        }
      }),
      takeWhile((response, index) => response['StatusCode'] === '730' && index <= maxRetries, true),
      toArray(),
      map(responses => responses[responses.length - 1]),
      catchError(error => {
        console.error('Error:', error);
        return of(null);
      })
    );
  }
  _getFinYearCode(queryParam: string) {
    return this.http.get(API_GET_FINYAER_CODE + queryParam);
  }

  _getSampleCollectionRailRakeList(queryParam: string) {
    return this.http.get(API_SAMPLE_COLLECTION_RAIL_RAKE_LIST + queryParam);
  }

  _getSampleCollectionRailRakeListUnloading(queryParam: string) {
    return this.http.get(API_SAMPLE_COLLECTION_RAIL_RAKE_LIST_UNLOADING + queryParam);
  }
  _getSampleCollectionRailRakeDetails(queryParam: string) {
    return this.http.get(API_SAMPLE_COLLECTION_RAIL_RAKE_DETAILS + queryParam);
  }
  _getSampleCollectionMGRRakeDetails(queryParam: string) {
    return this.http.get(API_SAMPLE_COLLECTION_MGR_RAKE_DETAILS + queryParam);
  }
  _getSampleCollectionRailRakeSublots(queryParam: string) {
    return this.http.get(API_SAMPLE_COLLECTION_RAIL_RAKE_SUBLOTS + queryParam);
  }
  _getSampleCollectionMGRRakeSublots(queryParam: string) {
    return this.http.get(API_SAMPLE_COLLECTION_MGR_RAKE_SUBLOTS + queryParam);
  }
  _getRailRakeSublotDetails(queryParam: string) {
    return this.http.get(API_RAIL_RAKE_SUBLOT_DETAILS + queryParam);
  }

  _getAllSamplePartSizeForFeeder() {
    return this.http.get(API_GET_ALL_SAMPLE_FOR_FEEDER);
  }

  _getAllShift(queryParam: string) {
    return this.http.get(API_GET_ALL_SHIFT + queryParam);
  }

  _GetVTSRakeAdditionalInfoListing(queryParam: string) {
    return this.http.get(API_RAIL_RAKE_ADDTNL_INFO + queryParam);
  }

  _GetAllType() {
    return this.http.get(API_TYPE);
  }

  _GetAllLocation() {
    return this.http.get(API_LOCATION);
  }

  _postInsertVTSRakeAdditionalInfo(Data: any) {
    return this.http.post(API_InsertVTSRakeAdditionalInfo, Data);
  }
  _postInsertVTSRakeAdditionalInfoFile(Data: any) {
    return this.http.post(API_InsertVTSRakeAdditionalInfoFile, Data);
  }

  // FOR TRUCK

  _GetVTSTruckAdditionalInfoListing(queryParam: string) {
    return this.http.get(API_TRUCK_ADDTNL_INFO + queryParam);
  }

  _GetAllTypeTruck() {
    return this.http.get(API_TRUCK_TYPE);
  }

  _GetAllLocationTruck() {
    return this.http.get(API_TRUCK_LOCATION);
  }

  _postInsertVTSTruckAdditionalInfo(Data: any) {
    return this.http.post(API_InsertVTSTruckAdditionalInfo, Data);
  }

  //For New Version //
  _getBinAssignShortageOnBinSend(qString: any) {
    return this.http.get(API_GET_BIN_ASSIGN_SHORTAGE_ON_BIN_SEND + qString)
  }
  _postSampleCollectionMGRNewSave(Data: any) {
    let retryCount = 0;
    const maxRetries = 2;

    return this.http.post(API_SAMPLE_COLLECTION_MGR_RAKE_SAVE, Data).pipe(
      expand(response => {
        if (response['StatusCode'] === '730' && retryCount < maxRetries) {
          retryCount++;
          return this.http.post(API_SAMPLE_COLLECTION_MGR_RAKE_SAVE, Data);
        } else {
          return of(response);
        }
      }),
      takeWhile((response, index) => response['StatusCode'] === '730' && index <= maxRetries, true),
      toArray(),
      map(responses => responses[responses.length - 1]),
      catchError(error => {
        console.error('Error:', error);
        return of(null);
      })
    );
  }

  _getSampleCollectionMGRRakeList(queryParam: string) {
    return this.http.get(API_SAMPLE_COLLECTION_MGR_RAKE_LIST + queryParam);
  }
  _getMGRRakeSublotDetails(queryParam: string) {
    return this.http.get(API_MGR_RAKE_SUBLOT_DETAILS + queryParam);
  }

  _getSampleCollectionConveyorList(queryParam: string) {
    return this.http.get(API_SAMPLE_COLLECTION_cONVEYOR_LIST + queryParam);
  }
 
  _getSampleCollectionConveyorListUnloading(queryParam: string) {
    return this.http.get(API_SAMPLE_COLLECTION_cONVEYOR_LIST_UNLOADING + queryParam);
  }

  _postSampleCollectionConveyorSave(rrbfData: any) {
    let retryCount = 0;
    const maxRetries = 2;

    return this.http.post(API_SAMPLE_COLLECTION_cONVEYOR_BELT, rrbfData).pipe(
      expand(response => {
        if (response['StatusCode'] === '730' && retryCount < maxRetries) {
          retryCount++;
          return this.http.post(API_SAMPLE_COLLECTION_cONVEYOR_BELT, rrbfData);
        } else {
          return of(response);
        }
      }),
      takeWhile((response, index) => response['StatusCode'] === '730' && index <= maxRetries, true),
      toArray(),
      map(responses => responses[responses.length - 1]),
      catchError(error => {
        console.error('Error:', error);
        return of(null);
      })
    );
  }

  _postSampleCollectionTruckRcsSave(rrbfData: any) {
    let retryCount = 0;
    const maxRetries = 2;

    return this.http.post(API_SAMPLE_COLLECTION_TRUCK_RCS_SAVE, rrbfData).pipe(
      expand(response => {
        if (response['StatusCode'] === '730' && retryCount < maxRetries) {
          retryCount++;
          return this.http.post(API_SAMPLE_COLLECTION_TRUCK_RCS_SAVE, rrbfData);
        } else {
          return of(response);
        }
      }),
      takeWhile((response, index) => response['StatusCode'] === '730' && index <= maxRetries, true),
      toArray(),
      map(responses => responses[responses.length - 1]),
      catchError(error => {
        console.error('Error:', error);
        return of(null);
      })
    );
  }
  _getSampleCollectionTruckListRcs(queryParam: string) {
    return this.http.get(API_SAMPLE_COLLECTION_TRUCK_LIST_RCS + queryParam);
  }
  
  // FOR TRUCK

  _GetVTSConveyorBeltAdditionalInfoListing(queryParam: string) {
    return this.http.get(API_CONVEYOR_BELT_ADDITIONAL_INFO_LISTING + queryParam);
  }

  _InsertVTSConveyorBeltAdditionalInfo(Data: any) {
    return this.http.post(API_INSERT_VTS_CONVEYOR_BELT_ADDITIONAL_INFO, Data);
  }
  
  _GetVTSRCRAdditionalInfoListing(queryParam: string) {
    return this.http.get(API_GET_VTS_RCR_ADDITIONAL_INFO_LISTING + queryParam);
  }

  _InsertVTSRCRAdditionalInfo(Data: any) {
    return this.http.post(API_INSERT_VTS_RCR_ADDITIONAL_INFO, Data);
  }
  
 
  _PostSampleCollectionRopeWayNewEntry(rrbfData: any) {
    let retryCount = 0;
    const maxRetries = 2;

    return this.http.post(API_INSERT_SAMPLE_COLLECTION_ROPEWAY, rrbfData).pipe(
      expand(response => {
        if (response['StatusCode'] === '730' && retryCount < maxRetries) {
          retryCount++;
          return this.http.post(API_INSERT_SAMPLE_COLLECTION_ROPEWAY, rrbfData);
        } else {
          return of(response);
        }
      }),
      takeWhile((response, index) => response['StatusCode'] === '730' && index <= maxRetries, true),
      toArray(),
      map(responses => responses[responses.length - 1]),
      catchError(error => {
        console.error('Error:', error);
        return of(null);
      })
    );
  }

  _getSampleCollectionRopeWayList(queryParam: string) {
    return this.http.get(API_ROPEWAY_LOADING_LISTING + queryParam);
  }
  
  _GetVTSRopewayAdditionalInfoListing(queryParam: string) {
    return this.http.get(API_ROPEWAY_ADDITIONAL_INFO_LISTING + queryParam);
  }
  _InsertVTSRopeWayAdditionalInfo(Data: any) {
    return this.http.post(API_INSERT_VTS_ROPEWAY_BELT_ADDITIONAL_INFO, Data);
  }
}
