import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LocationStrategy, HashLocationStrategy, CommonModule } from '@angular/common';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { ReactiveFormsModule } from '@angular/forms';
import { AuthenticationService } from './admin/_services';
import { JwtInterceptor, ErrorInterceptor } from './admin/_helper';
import { Helper } from './admin/_helper/default';
import { NgxSpinnerModule } from 'ngx-spinner';
import { LoaderInterceptor } from './admin/_helper/loader.interceptor';
import { ToastrModule, ToastContainerModule } from 'ngx-toastr';
import { CustomAlertComponent } from './admin/_helper/custom-alert/custom-alert.component';
import { MatDialogModule } from '@angular/material/dialog';
import { NgZorroAntdModule, NZ_I18N, en_US } from 'ng-zorro-antd';
import { SampleCollectionAlertComponent } from './admin/_helper/sample-collection-alert/sample-collection-alert.component';
import { ConfirmAlertComponent } from './admin/_helper/confirm-alert/confirm-alert.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';


@NgModule({
  declarations: [
    AppComponent, CustomAlertComponent, ConfirmAlertComponent
  ],
  entryComponents: [CustomAlertComponent, SampleCollectionAlertComponent, ConfirmAlertComponent ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgxMaterialTimepickerModule,
    MatDialogModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    ToastContainerModule,
    ToastrModule.forRoot({ timeOut: 3000,  positionClass: 'toast-top-center', preventDuplicates: true,}),
    NgZorroAntdModule,
    NgxPaginationModule,
    MatIconModule,
    MatMenuModule
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    AuthenticationService,
    Helper,

    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: NZ_I18N, useValue: en_US }
   
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
