import { Component, OnInit } from '@angular/core';
import { AuthenticationService, MasterService } from '../../_services';
import { User } from '../../_models';
import { FormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Router } from '@angular/router';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  formData: any;
  user: User;
  plantList: any;
  selectedPlant: number;
  public NotificationList: any[] = [];
  constructor(
    private authService: AuthenticationService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private deviceService: DeviceDetectorService, 
    private router: Router,
    private masterService: MasterService,) { }

  ngOnInit() {
    this.user = JSON.parse(sessionStorage.getItem('limsUser'));
    // this.formData = this.fb.group({ PlantID: [Number(this.user['PlantID'])] })
    //this.getAllNotificationDetails();
  }

  get device(): any { return this.deviceService.getDeviceInfo(); }
  get isMobile(): boolean { return this.deviceService.isMobile(); }
  get isTablet(): boolean { return this.deviceService.isTablet(); }
  get isDesktop(): boolean { return this.deviceService.isDesktop(); }

  // setPlant(plantId: number): void {
  //   this.authService.loginAtPlantChange(plantId).subscribe(
  //     (res: any) => {
  //       if (res.access_token) {
  //         // this.snackBar.open('Plant Change ', '', {
  //         //   duration: 2000,
  //         // });
  //         //  this.router.navigate(['/admin/app/dashboard']);
  //       window.location.reload();
  //       } else {
  //         // this.snackBar.open('Credintial Error to Change Plant', '', {
  //         //   duration: 5000,
  //         // });
  //       }
  //     },
  //     (err: any) => {
  //       // this.snackBar.open(err + ' :: Credintial Error to Change Plant', '', {
  //       //   duration: 5000,
  //       // });
  //     });
  // }

  // getAllNotificationDetails() {
  //   this.masterService._getAllNotificationDetails().subscribe((res: any) => {
  //     this.NotificationList = res;
  //     console.log(res)
  //   })
  // }

  // logout() {
  //   this.authService._logout();
  // }

  logout() {
    const deviceInfo = {
      BrowserName: this.device.browser,
      BrowserVersion: this.device.browser_version,
      platform: this.isMobile ? 'Mobile' : this.isTablet ? 'Tablet' : this.isDesktop ? 'Desktop' : 'Not Indentified'
    }
    this.authService._logout(deviceInfo).subscribe(
      (res: any) => {
        if(res.Status==1){
          console.log(res)
          sessionStorage.removeItem('limsUser');
        sessionStorage.clear();
        localStorage.clear();
        this.router.navigate(['admin/login']);
        }
    })
  }

}
